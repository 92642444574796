import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import dateFormat from 'dateformat'
import { CSVLink } from "react-csv"

import {
	Flex,
	Heading,
	Paragraph,
} from 'reakit'

import { Container, Sidebar, Body, Footer, Modal } from '../../../components/layouts'

import {
	DynamicTable,
	Card,
	Loader,
	DateRange,
	Button,
	CheckBox,
	Image
} from '../../../components/ui'

import {
	getDonations,
	getStatistics,
	getProjects,
	refreshToken
} from '../../../factory'

import currencyFormat from '../../../util/currencyFormat'
import parseDate from '../../../util/parseDate'

import Layout from '../../../components/layout'

import theme from '../../../theme/content'

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			bodySize: 0,
			filter: false,
			filterModal: false,
			selectedProjectArray: []
		}

		this.getStats = this.getStats.bind(this)
		this.getDonations = this.getDonations.bind(this)
		this.getProjects = this.getProjects.bind(this)
		this.getBodyWidth = this.getBodyWidth.bind(this)
		this.getDonationType = this.getDonationType.bind(this)
		this.changeDates = this.changeDates.bind(this)
		this.filterDonations = this.filterDonations.bind(this)
	}

	componentDidMount() {
		this.props.refreshToken().then((res)=> {
			this.getStats(res)
			this.getDonations(res)
			this.getProjects()
		})

		this.getBodyWidth()


		window.addEventListener('resize', this.getBodyWidth.bind(this))
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.getBodyWidth.bind(this))
	}

	getBodyWidth() {
		this.setState({
			bodySize: (window.innerWidth - 280)
		})
	}

	getStats(token) {
		var now = new Date()
		var from = new Date()
		from.setDate(now.getDate() - 15)

		const statsParams = {
			organization_id: this.props.organization.id,
			date_from: dateFormat(from, 'mm-dd-yy HH:mm:ss'),
			date_to: dateFormat(now, 'mm-dd-yy HH:mm:ss'),
			period: 1
		}

		this.props.onGetStatistics(statsParams, token)
	}

	getDonations(token) {
		this.props.onGetDonations(this.props.organization.id, token)
	}

	getProjects() {
		this.props.onGetProjects(this.props.organization.id)
	}

	getDonationType(type) {
		switch (type) {
		case 0: return 'Roundup'
		case 1: return 'Daily'
		default: return 'One-Time'
		}
	}

	changeDates() {
		this.setState(prevState => {filter: !prevState.filter})
	}

	filterDonations(donation) {
		const {startDate, endDate} = this.props.stats

		let dateValid = true
		let projectValid = true
 		const paymentStateValid = donation.payment_state < 3

		if (this.state.filter && !(startDate.valueOf() <= Date.parse(donation.date_added) && Date.parse(donation.date_added) <= endDate.valueOf())) {
			dateValid = false
		}
		if (this.state.projectFilter && !this.state.selectedProjectArray.includes(donation.project_id)) {
			projectValid = false
		}
		return paymentStateValid && dateValid && projectValid && donation
	}

	render() {
		const csvData = this.props.donations ? this.props.donations.filter(this.filterDonations).map((donation) => {
			const tempDonation = Object.assign({}, donation)
			delete tempDonation.organization_title
			delete tempDonation.organization_id
			delete tempDonation.payment_state
			delete tempDonation.project_id
			delete tempDonation.id
			tempDonation.donation_type = this.getDonationType(tempDonation.donation_type)
			delete tempDonation.user_id
			return tempDonation
		}) : []

		const {pending, donations} = this.props

		if (pending) {
			return (
				<Layout>
					<Container>
						<Sidebar content={theme.adminSidebar} />
						<Body>
							<div style={{minHeight: 'calc(100vh - 200px)'}}>
								<Flex row marginBottom="2em" width="100%">
									<Card padding="1em" width="100%">
										<Flex row justifyContent="space-between">
											<Flex style={{flex: 3}}>
												<div style={{display: 'flex'}}>
													<Heading as="h3" margin="0 0 0.5em 0">Loading Donations...</Heading>
													<Loader/>
												</div>
											</Flex>
										</Flex>
										<DynamicTable data={donations}
											columns={[
												{ Header: 'Date', accessor: 'date_added', width: 150, Cell: props => parseDate(props.value),
													sortMethod: (a, b) => {
														return Date.parse(a) > Date.parse(b) ? 1 : -1
													}
										 },
												{ Header: 'Project', accessor: 'project_name'},
												{ Header: 'Sum', accessor: 'sum', width: 100, Cell: props => currencyFormat(props.value) },
												{ Header: 'Status', accessor: 'payment_state', width: 100, Cell: props => props.value === 2 ? <i className="fas fa-check" /> : <i className="fas fa-spinner" /> },
											]}
											sortBy={[
												{
													id: "date_added",
													desc: true
												}
											]}
											paginate
											maxRows={20}
										/>
									</Card>
								</Flex>
							</div>
							<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
								<Footer />
							</Flex>
						</Body>
					</Container>
				</Layout>
			)
		}

		if (this.props.donations && this.props.donations.length === 0) {
			return (
				<Layout>
					<Container>
						<Sidebar content={theme.adminSidebar} />
						<Body>
							<div style={{minHeight: 'calc(100vh - 200px)'}}>
								<Flex row justifyContent="space-around" marginBottom="1em" width="100%">
									<Card padding="1em" minHeight={'100%'}>
										<Container column >
											<Flex row width="100%" justifyContent={'center'} alignItems={"center"}>
												<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px">Uh Oh! You don't have any donations yet...</Heading>
											</Flex>
											<Flex row justifyContent={'center'} alignItems={"center"} width="100%">
												<Image
													height="250px"
													width="auto"
													src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding2%402x.png'}/>
											</Flex>
											{this.props.organization && this.props.organization.donor_qty ?
												<Flex column width="100%" justifyContent={'center'} alignItems={"center"}>
													<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px">But you have {this.props.organization.donor_qty} users and they are on their way to becoming donors!</Heading>
													<Paragraph style={{textAlign: 'center', marginRight: 50}}> Check back in a few days to see how much they have given! Just a heads up, we don't show donors until your donations have completed processing. If your donations are pending (dashed circle), then check back in a few days for a list of donors! </Paragraph>
												</Flex> :
												<Flex column width="100%" justifyContent={'center'} alignItems={"center"} style={{textAlign: 'center'}}>
													<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px" style={{textAlign: 'center'}}>Your first step is to add some donors to the app.</Heading>
													<Paragraph>Need some help? Call us at (737)228-3586, or send us an email at <a href="mailto:nonprofits@flourishchange.com">nonprofits@flourishchange.com</a></Paragraph>
												</Flex>
											}
										</Container>
									</Card>
								</Flex>
							</div>
							<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
								<Footer />
							</Flex>
						</Body>
					</Container>
				</Layout>
			)
		}

		return (
			<Layout>
				<Container>
					<Sidebar content={theme.adminSidebar} />
					<Body>
						<div style={{minHeight: 'calc(100vh - 200px)'}}>
							<Flex row justifyContent="space-around" marginBottom="1em" width="100%">
								<Card padding="1em" minHeight={'100%'}>
									<div style={{flexDirection: 'row', display: 'flex'}}>
										<Flex style={{flex: 4, flexDirection: 'row', justifyContent: 'flexStart'}}>
											<Heading as="h3" margin="0 0.5em 0.5em 0">Your Donations</Heading>
											{donations && donations.length > 0 &&	<Modal
												containterStyle={{minWidth: '70%'}}
												button={{text: 'Filter Donations', width: '10em'}}
												style={{justifyContent: 'flexStart'}}
											>
												<Flex row justifyContent="space-between">
													<Heading as="h4">Filter Donations</Heading>
													<Button onClick={()=>this.setState({filter: false, projectFilter: false, selectedProjectArray: []})}>Clear filters</Button>
												</Flex>
												<Heading as="h6">By Date</Heading>
												<Flex row style={{marginTop: 25, paddingRight: 25}}>
													<CheckBox
														label={'All Time'}
														name={"Include Forever"}
														defaultSelected={!this.state.filter}
														onChange={()=>{
															this.setState({filter: !this.state.filter})
														}}
														style={{marginRight: 50, marginTop: 10, marginBottom: 10}}
													/>
													{this.state.filter && <DateRange disabled backgroundColor="white" textColor="black" onChange={()=>this.setState({filter: true})}/>}
												</Flex>
												<Heading as="h6">Projects</Heading>
												{this.props.projects.map((proj, idx) => {
													return (<CheckBox
														label={proj.name}
														value={proj.id}
														key={proj.id}
														name={`${idx}`}
														defaultSelected={this.state.selectedProjectArray.includes(proj.id)}
														onChange={(box)=>{
															const id = parseInt(box.target.value, 10)
															let arr = this.state.selectedProjectArray
															if (arr.includes(id)) {
																arr = arr.filter(v => v !== id)
															} else {
																arr.push(id)
															}
															this.setState({selectedProjectArray: arr})
															if (arr.length > 0) {
																this.setState({projectFilter: true})
															} else {
																this.setState({projectFilter: false})
															}
														}}
													/>)
												}

												)}
											</Modal>
											}
										</Flex>
										{donations && donations.length > 0 && this.props.organization && <CSVLink
											filename={`${this.props.organization.title.replace(/ /g, '_')}_Flourish_Donations.csv`}
											style={{marginLeft: 15,
												fontFamily: theme.fonts.primary,
												textDecoration: 'none',
												flex: 1
											}}
											data={csvData}>
											Download
										</CSVLink>}
									</div>
									<DynamicTable data={donations.filter(this.filterDonations)}
										columns={[
											{ Header: 'Date', accessor: 'date_added', width: 150, Cell: props => parseDate(props.value),
												sortMethod: (a, b) => {
													return Date.parse(a) > Date.parse(b) ? 1 : -1
												}
									 },
											{ Header: 'Project', accessor: 'project_name'},
											{ Header: 'Sum', accessor: 'sum', width: 100, Cell: props => currencyFormat(props.value) },
											{ Header: 'Status', accessor: 'payment_state', width: 100, Cell: props => props.value === 2 ? <i className="fas fa-check" /> : <i className="fas fa-spinner" /> },
										]}
										sortBy={[
											{
												id: "date_added",
												desc: true
											}
										]}
										paginate
										maxRows={20}
									/>
								</Card>
							</Flex>
						</div>
						<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
							<Footer />
						</Flex>
					</Body>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	onGetDonations: PropTypes.func,
	onGetStatistics: PropTypes.func,
	onGetProjects: PropTypes.func,
	organization: PropTypes.object,
	donations: PropTypes.array,
	projects: PropTypes.array,
	stats: PropTypes.object,
	token: PropTypes.string,
	pending: PropTypes.bool,
	refreshToken: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		organization: state.authState.adminOrg || state.adminState.organization,
		donations: state.adminState.donations,
		projects: state.donateNowState.projects,
		stats: state.statistics,
		token: state.authState.accessToken,
		pending: state.adminState.pending
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onGetDonations: (orgId, token) => dispatch(getDonations(orgId, token)),
		onGetProjects: (orgId) => dispatch(getProjects(orgId)),
		onGetStatistics: (params, token) => dispatch(getStatistics(params, token)),
		refreshToken: () => dispatch(refreshToken())
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
